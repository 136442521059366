@import url("https://fonts.googleapis.com/css?family=Orbitron:700|Orbitron:400");

:root {
  --text: #ffffff;
  --background: #5f5f5f;
  --primary: #0cafd4;
  --secondary: #3d3d3d;
  --accent: #1ea43b;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: "Orbitron";
  font-weight: 400;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: "Orbitron";
  font-weight: 700;
}

html {
  font-size: 100%;
} /* 16px */

h1 {
  font-size: 4.21rem; /* 67.36px */
}

h2 {
  font-size: 3.158rem; /* 50.56px */
}

h3 {
  font-size: 2.369rem; /* 37.92px */
}

h4 {
  font-size: 1.777rem; /* 28.48px */
}

h5 {
  font-size: 1.333rem; /* 21.28px */
}

small {
  font-size: 0.75rem; /* 12px */
}

.home-wrapper {
  background: linear-gradient(
    109deg,
    var(--background) 69%,
    var(--secondary) 69.05%
  );
  /* backdrop-filter: blur(10px); */
}

.home-container {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url("https://i.imgur.com/UitDSDM.jpg");
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
}

.title-wrapper {
  display: flex;
  flex-direction: column;
  width: 40%;
}

.home-container > .title-wrapper > h1 {
  color: var(--text);
  font-size: 50px;
  margin-right: 5%;
  text-shadow: 5px 5px #000000;
}

/* .home-container > .title-wrapper > h1 > span {
  color: transparent;
  font-size: 50px;
  -webkit-text-stroke: 1px var(--accent);
} */

.home-container > .title-wrapper > h2 {
  color: var(--primary);
  font-size: 30px;
  text-align: center;
  margin-top: 3%;
  font-weight: 200;
  text-shadow: 5px 5px #000000;
}

.circle-wrapper {
  position: relative;
  width: 250px;
  height: 250px;
  float: left;
  margin: 10px;
}

.image-icon {
  position: absolute;
  color: #fff;
  font-size: 55px;
  top: 125px;
  left: 125px;
  transform: translate(-50%, -50%);
  height: 240px;
  width: 240px;
  object-fit: cover;
  border-radius: 50%;
  object-position: -5px -115px;
  background-color: rgb(233, 233, 233);
}

.outline {
  background-color: var(--primary);
  border: 2.5px dashed var(--primary);
}

.circle {
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  padding: 2.5px;
  background-clip: content-box;
  animation: spin 35s linear infinite;
}

.circle-wrapper:active .circle {
  animation: spin 2s linear infinite;
}

@keyframes spin {
  100% {
    transform: rotateZ(360deg);
  }
}

.card-wrapper {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.card-container {
  height: 400px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: start;
}

@media only screen and (max-width: 600px) {
  .card-container {
    height: 700px;
    width: 100%;
    flex-direction: column;
  }
}

.card {
  width: 80px;
  border-radius: 0.75rem;
  background-size: cover;
  cursor: pointer;
  overflow: hidden;
  /* border-radius: 2rem; */
  margin: 0 10px;
  display: flex;
  align-items: flex-end;
  transition: 0.6s cubic-bezier(0.28, -0.03, 0, 0.99);
  box-shadow: 0px 10px 30px -5px rgb(3, 3, 3);
}

.card > .row {
  color: white;
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-end;
}

.card > .row > .icon {
  background: #0cafd452;
  color: white;
  border-radius: 50%;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 15px;
  height: 50px;
}

.card > .row > .description {
  display: flex;
  justify-content: center;
  flex-direction: column;
  overflow: hidden;
  height: 400px;
  width: 520px;
  opacity: 0;
  transform: translateY(30px);
  transition-delay: 0.3s;
  transition: all 0.3s ease;
  background-color: #6d6d6db4;
  border-radius: 4px;
  padding-left: 10px;
  letter-spacing: 0.12em;
}

.open-main-nav {
  position: absolute;
  top: 15px;
  padding-top: 20px;
  right: 15px;
  background: none;
  border: 0;
  cursor: pointer;
}

.open-main-nav:focus {
  outline: none;
}

/* .hiddenNav {
  opacity: 0;
  filter: blur (5px);
  transform: translateX(-100%);
  transition: all 1s;
} */

.open {
  display: flex !important;
  z-index: 1;
}

@media only screen and (max-width: 600px) {
  .card > .row > .description {
    font-size: 8px;
    height: 233px;
    width: 270px;
  }
  .description h4 {
    font-size: 12px;
  }
}

.description p {
  color: var(--text);
  padding-top: 10px;
}

.description a {
  text-decoration: none;
  color: white;
  padding-top: 10px;
}

.description h4 {
  text-transform: uppercase;
}

.card-input {
  display: none;
}

.card-input:checked + label {
  width: 600px;
}

@media only screen and (max-width: 600px) {
  .card-input:checked + label {
    width: 350px;
  }
}

.card-input:checked + label .description {
  opacity: 1 !important;
  transform: translateY(0) !important;
}

.card[for="c1"] {
  background-image: url("https://i.imgur.com/IAeVmcI.jpg");
}
.card[for="c2"] {
  background-image: url("https://i.imgur.com/Ew1ILia.jpg");
}
.card[for="c3"] {
  background-image: url("https://i.imgur.com/f0jzwrF.jpg");
}
.card[for="c4"] {
  background-image: url("https://i.imgur.com/f0jzwrF.jpg");
}

#tsparticles {
  position: absolute !important;
  height: 100vh !important;
  width: 100% !important;
}

.text-left {
  padding: 0 10px;
  text-decoration: none;
  color: white;
}

.text-left:hover {
  cursor: pointer;
  color: var(--primary);
}

.end {
  align-items: flex-end;
}

.hidden {
  opacity: 0;
  filter: blur (5px);
  transform: translateX(-100%);
  transition: all 1s;
}

.show {
  opacity: 1;
  filter: blur(0);
  transform: translateX(0);
}

@media (prefers-reduced-motion) {
  .hidden {
    transition: none;
  }
}

@media only screen and (max-width: 600px) {
  .home-container {
    gap: 10%;
    flex-direction: column;
  }
  .title-wrapper {
    width: 90%;
    text-align: center;
  }
  .home-container > .title-wrapper > h1 {
    font-size: 40px;
    margin-right: 0;
  }
  .home-container > .title-wrapper > h2 {
    font-size: 20px;
  }
  .image-icon {
    height: 200px;
    width: 200px;
    object-position: -5px -95px;
    top: 105px;
    left: 105px;
  }
  .circle-wrapper {
    width: 210px;
    height: 210px;
  }
  form > h1 {
    font-size: 40px;
  }
}

/* ---------------- Navbar and burger menu ------------------ */
nav {
  width: 100%;
  height: 5em;
  display: flex;
  justify-content: flex-end;
  padding: 1em;
}

.burger-menu {
  height: 40%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  cursor: pointer;
  z-index: 3;
}

.burger-bar {
  width: 1.5em;
  height: 0.2em;
  background-color: rgb(253, 253, 253);
  border-radius: 0.5em;
}

.menu {
  width: 100%;
  height: 100vh;
  background-color: #161616;
  position: absolute;
  top: 0;
  z-index: 2;
}

/* ------------- sliding menu ------------------ */
.hiddenNav {
  opacity: 0;
  filter: blur (5px);
  transform: translateX(-100%);
  transition: all 1s;
  /* display: none; */
}

.visible {
  /* display: inherit; */
  opacity: 1;
  filter: blur (5px);
  transform: translateX(0%);
  transition: all 1s;
}

/* ----- animations--------- */
/* clicked */
.burger-bar.clicked:nth-child(1) {
  transform: rotate(45deg) translate(0.6em, 0.25em);
  transition: ease-out 0.5s;
}

.burger-bar.clicked:nth-child(2) {
  transform: scale(0.1);
  transition: ease-out 0.5s;
}

.burger-bar.clicked:nth-child(3) {
  transform: rotate(135deg) translate(-0.5em, 0.1em);
  transition: ease-out 0.5s;
}

/* unclicked */
.burger-bar.unclicked {
  transform: rotate(0) translate(0);
  transition: cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.5s;
}

@media only screen and (min-width: 600px) {
  nav {
    display: none;
  }
}
